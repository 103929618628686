<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ languages[$i18n.locale] }}
        </p>
      </div>
    </template>

    <b-dropdown-item
      v-for="code in ['uk', 'ru']"
      :key="code"
      link-class="d-flex align-items-center"
      @click="changeLanguage(code)"
    >
      <span>{{ languages[code] }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(useJwt.jwtConfig.userDataKey)),
      avatarText,
      languages: {
        uk: 'Українська',
        ru: 'Русский',
      },
    }
  },
  methods: {
    logout() {
      useJwt.logout()
        .then(() => {
          this.success()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.success()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    success() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.userDataKey)

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    changeLanguage(code) {
      this.$i18n.locale = code
      this.$i18n.fallbackLocale = code
    },
  },
}
</script>
