export default [
  {
    title: 'admin.navigation.orders',
    route: 'orders-index',
    icon: 'DollarSignIcon',
    action: 'index',
    resource: 'orders',
  },
  {
    title: 'admin.navigation.messages',
    route: 'messages-index',
    icon: 'MailIcon',
    action: 'index',
    resource: 'messages',
  },
  {
    title: 'admin.navigation.marketing',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'admin.navigation.coupons',
        route: 'coupons-index',
        action: 'index',
        resource: 'coupons',
      },
      {
        title: 'admin.navigation.reviews',
        route: 'reviews-index',
        icon: 'PenToolIcon',
        action: 'index',
        resource: 'reviews',
      },
    ],
  },
  {
    title: 'admin.navigation.seo',
    icon: 'AnchorIcon',
    children: [

      {
        title: 'admin.navigation.seo_pages',
        route: 'seo_pages-index',
        action: 'index',
        resource: 'seo_pages',
      },
      {
        title: 'admin.navigation.seo_templates',
        route: 'seo_templates-index',
        action: 'index',
        resource: 'seo',
      },
    ],
  },
  {
    title: 'admin.navigation.feeds',
    icon: 'RefreshCcwIcon',
    children: [
      {
        title: 'admin.navigation.export',
        route: 'feeds-index',
        action: 'index',
        resource: 'feeds',
      },
      {
        title: 'admin.navigation.import',
        route: 'imports-index',
        action: 'index',
        resource: 'feeds',
      },
    ],
  },
  {
    title: 'admin.navigation.reports',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'admin.navigation.reports_orders',
        route: { name: 'reports-show', params: { type: 'orders' } },
        action: 'index',
        resource: 'reports',
      },
    ],
  },
]
