export default [
  {
    title: 'admin.navigation.pages',
    icon: 'ColumnsIcon',
    children: [
      {
        title: 'admin.navigation.pages',
        route: 'pages-index',
        action: 'index',
        resource: 'pages',
      },
      {
        title: 'admin.catalog.title',
        route: { name: 'pages-catalog-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'admin.navigation.sale',
        route: { name: 'pages-sale-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'admin.navigation.novelty',
        route: { name: 'pages-novelty-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'admin.navigation.bestseller',
        route: { name: 'pages-bestseller-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'admin.navigation.promotion',
        route: { name: 'pages-promotion-update' },
        action: 'update',
        resource: 'pages',
      },
      // {
      //   title: 'admin.navigation.coming_soon',
      //   route: { name: 'pages-coming_soon-update' },
      //   action: 'update',
      //   resource: 'pages',
      // },
    ],
  },
]
