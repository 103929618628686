export default [
  {
    header: 'admin.navigation.system_pages',
  },
  {
    title: 'admin.navigation.system',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'admin.navigation.file-manager',
        route: 'file-manager',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'admin.navigation.settings',
        route: 'settings',
        action: 'index',
        resource: 'settings',
      },
      // {
      //   title: 'admin.navigation.languages',
      //   route: 'languages',
      //   action: 'index',
      //   resource: 'languages',
      // },
      {
        title: 'admin.navigation.translations',
        route: 'translations',
        action: 'index',
        resource: 'translations',
      },
      {
        title: 'admin.navigation.redirects',
        route: 'redirects',
        action: 'index',
        resource: 'translations',
      },
      {
        title: 'admin.navigation.currencies',
        route: 'currencies-index',
        action: 'index',
        resource: 'currencies',
      },
      {
        title: 'admin.navigation.stocks',
        route: 'stocks-index',
        action: 'index',
        resource: 'stocks',
      },
      {
        title: 'admin.navigation.shops',
        route: 'shops-index',
        action: 'index',
        resource: 'shops',
      },
      {
        title: 'admin.navigation.statuses',
        route: 'statuses-index',
        action: 'index',
        resource: 'shops',
      },
      {
        title: 'admin.navigation.payment_methods',
        route: 'payment_methods-index',
        action: 'index',
        resource: 'payment_methods',
      },
      {
        title: 'admin.navigation.delivery_methods',
        route: 'delivery_methods-index',
        action: 'index',
        resource: 'delivery_methods',
      },
    ],
  },
]
